// Pulse animation

.pulse {
	animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.85) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10 rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(.85) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


// Fade in transition
.fade-in-enter {
	opacity: 0;
}
.fade-in-enter-active {
	opacity: 1;
	transition: opacity 1000ms ease-out;
}
.fade-in-exit {
	opacity: 1;
}
.fade-in-exit-active {
	opacity: 0;
	transition: opacity 1000ms ease-out;
}


// Fade up/down
.fade-up-down-enter,
.fade-up-down-appear {
	opacity: 0;
	transform: translateY(150px);
}
.fade-up-down-enter-active,
.fade-up-down-appear-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 800ms ease-out;
}
.fade-up-down-exit {
	opacity: 1;
}
.fade-up-down-exit-active {
	opacity: 0;
	transform: translateY(150px);
	transition: all 800ms ease-out;
}