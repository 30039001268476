@import "../App.scss";
@import "../breakpoints";

.main-rainbow > div {
	overflow: initial;
}

.main-grid,
.main-rainbow {
	padding: 7rem 0 3.5rem 0;
}

.all-artworks {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	&:focus {
		outline: none;
	}

	.artwork-dot {
		position: absolute;
		left: auto;
		z-index: 2;
		border-radius: 50%;
		cursor: pointer;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
			transition: opacity 1500ms ease-in-out;
		}
	}

	.temp-dot {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		border-radius: 50%;
		background-color: #e4000f;
		transform-origin: 0 0;
		@extend .pulse;
	}

	&.artworks-grid {
		align-items: flex-start;
		height: auto;

		.grid-column {
			&:nth-of-type(1) {
				margin-top: 6rem;
			}
			&:nth-of-type(2) {
				margin-top: 3rem;
			}
		}

		.artwork-dot {
			position: relative;
		}
	}

	&.artworks-rainbow {
		display: flex;
		flex-wrap: wrap;
		justify-content: initial;
		align-items: initial;
		align-content: center;

		.grid-row {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@for $i from 2 through 8 {
				&:nth-of-type(#{$i}) {
					margin-left: #{$i * 1.5}rem;
				}
			}

			@include breakpoint (m) {
				&:nth-of-type(2) {
					margin-left: 3rem;
				}
				&:nth-of-type(3) {
					margin-left: 6rem;
				}
				&:nth-of-type(4) {
					margin-left: 9rem;
				}
			}
		}

		.artwork-dot {
			position: initial;
		}
	}

	&.artworks-jackpot {

		.artwork-dot {
			overflow: hidden;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			max-width: 400px;
		
			@include breakpoint (m) {
				max-width: 548px;
			}

			&::before {
				content: '';
				display: block;
				padding-bottom: 100%;
			}

			img {
				transition: opacity 2000ms ease-in-out;
			}
		}
	}

	&.artworks-jackpot,
	&.artworks-grid {
		.artwork-dot {
			overflow: hidden;
		}

		img {
			position: absolute;
			top: -2px;
			right: -2px;
			bottom: -2px;
			left: -2px;
			width: 102%;
			height: 102%;
		}
	}
}

.invisible-grid {
	display: grid;
	position: absolute;

	&.map {
		top: 50%;
		grid-template-columns: repeat(14, auto);
		grid-template-rows: repeat(13, auto);
		grid-gap: 4px;
		transform: translateY(-50%);

		@include breakpoint (640px) {
			grid-gap: 6px;
			// auto 17 times because 17 columns in the desktop grid
			grid-template-columns: repeat(17, auto);
			// row 9 times because 9 rows in the desktop grid
			grid-template-rows: repeat(9, auto);
		}
	}

	&.grid {
		top: 40%;
		grid-template-columns: repeat(3, auto);
		grid-template-rows: repeat(34, auto);
		grid-gap: 16px;

		.grid-box {
			&:nth-of-type(3n),
			&:nth-of-type(3n+1) {
				margin-top: -50%;
			}
		}
	}

	&.rainbow {
		display: flex;
		flex-wrap: wrap;
		left: 0;

		.grid-box {
			display: inline-block;

			&:nth-of-type(25n+1) {
				margin-left: -4rem;
			}
		}
	}

	.grid-box {
		// border: 1px solid #fff;
	}
}