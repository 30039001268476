@import "../breakpoints";

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	padding-top: 1.5rem;

	.header-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}

	.logo {
		flex-basis: 100%;
		margin-bottom: 1.5rem;
		text-align: center;

		svg {
			height: 22px;
		}
	}

	.layout-switcher {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-basis: 100%;

		svg {
			margin: 0 1rem;
		}
	}

	@include breakpoint (m) {
		padding-top: 3rem;

		.header-wrapper {
			flex-wrap: nowrap;
			justify-content: space-between;

			.logo {
				flex-basis: initial;
				margin: 0;
				text-align: initial;
			}

			.layout-switcher {
				flex-basis: initial;
				display: flex;
				justify-content: space-between;

				svg {
					margin: 0 0 0 2.5rem;
				}
			}
		}
	}
}


// Icons

#layout-grid-icon {
	#icon-base {
		display: block;
	}

	#icon-hover {
		display: none;
	}

	&:hover {
		#icon-base {
			display: none;
		}

		#icon-hover {
			display: block;

			circle {
				animation-name: show;
				animation-duration: 1400ms;
				animation-fill-mode: forwards;
				visibility: hidden;
			}

			@for $i from 1 through 9 {
				#circle-#{$i} {
					animation-delay: #{$i * 140 - 140}ms;
				}
			}
		}
	}
}

#layout-jackpot-icon {
	#icon-base {
		display: block;
	}

	#icon-hover {
		display: none;
	}

	&:hover {
		#icon-base {
			display: none;
		}

		#icon-hover {
			display: block;

			#shape-a, 
			#shape-r,
			#shape-t {
				animation-name: show-hide;
				animation-duration: 280ms;
				visibility: hidden;
			}

			#shape-a {
				animation-delay: 0ms;
			}
			#shape-r {
				animation-delay: 280ms;
			}
			#shape-t {
				animation-name: show;
				animation-duration: 280ms;
				animation-delay: 560ms;
				animation-fill-mode: forwards;
			}
		}
	}	
}

#layout-rainbow-icon {
	#icon-base {
		display: block;
	}

	#icon-hover {
		display: none;
	}

	&:hover {
		#icon-base {
			display: none;
		}

		#icon-hover {
			display: block;

			path {
				animation-name: show;
				animation-duration: 280ms;
				animation-fill-mode: forwards;
				visibility: hidden;
			}

			@for $i from 1 through 5 {
				#ellipse-#{$i} {
					animation-delay: #{$i * 140 - 140}ms;
				}
			}
		}
	}
}

#layout-home-icon {
	#icon-base {
		display: block;
	}

	#icon-hover {
		display: none;
	}

	&:hover {
		#icon-base {
			display: none;
		}

		#icon-hover {
			display: block;

			#group-1,
			#group-2,
			#group-3 {
				animation-name: show;
				animation-duration: 280ms;
				animation-fill-mode: forwards;
				visibility: hidden;
			}

			#group-1 {
				animation-delay: 280ms;
			}
			#group-2 {
				animation-delay: 560ms;
			}
			#group-3 {
				animation-delay: 840ms;
			}
		}
	}
}

@keyframes show {
	0% {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
}

@keyframes show-hide {
	0% {
		visibility: hidden;
	}
	50% {
		visibility: visible;
	}
	100% {
		visibility: hidden;
	}
}