@import "../breakpoints";

.all-artists {
	&:focus {
		outline: none;
	}
	table {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 840px;
		table-layout: fixed;
		color: #fff;
		text-align: left;

		thead {
			button {
				padding: 0;
				font-weight: 300;
				font-size: .75rem;
				letter-spacing: .5px;
				text-transform: uppercase;
				line-height: 1;
				border: none;
				color: #fff;
				outline: none;
				cursor: pointer;
				background-color: transparent;
			}
		}

		th {
			padding: 0 1rem .5rem 1rem;

			&:nth-of-type(1) {
				padding-left: 0;
				width: 30%;
			}
			&:nth-of-type(2) {
				width: 45%;
			}
			&:nth-of-type(3) {
				width: 35%;
			}
			&:nth-of-type(4) {
				width: 50px;
				padding-right: 0;
			}
		}

		tbody {
			font-family: 'Arvo', sans-serif;

			tr {
				background-image: linear-gradient(to right, #fff 15%, transparent 0%);
				background-position: bottom;
				background-size: 6px 1px;
				background-repeat: repeat-x;
				cursor: pointer;
			}

			td {
				padding: 1.5rem 0.5rem;
				vertical-align: top;

				@include breakpoint (m) {
					padding: 1.5rem 1rem;
				}

				&:nth-of-type(1) {
					padding-left: 0;
				}

				&:nth-of-type(4) {
					padding-right: 0;
				}

				&.artist-name {
					font-size: 0.7rem;
					font-weight: 600;

					@include breakpoint (m) {
						font-size: 1.375rem;
					}
				}

				&.artwork-title {
					font-size: 0.7rem;
					font-weight: 600;
					font-style: italic;

					@include breakpoint (m) {
						font-size: 1.375rem;
					}
				}

				&.for-sale {
					text-align: center;
				}

				&.for-sale svg {
					width:20px !important;
					height: 20px !important;

					@include breakpoint (m) {
						width:35px !important;
						height: 35px !important;
					}
				}

				&.site-name {
					font-size: 0.7rem;

					@include breakpoint (m) {
						font-size: 1.375rem;
					}
				}

				@include breakpoint (m) {
					word-break: initial;
				}
			}
		}
	}
}
