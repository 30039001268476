@mixin breakpoint($point) {
	@if $point == s {
		// @media (min-width: 56.25em)  { @content; }
		@media (min-width: 360px)  { @content; }
	}
	@else if $point == m {
		@media (min-width: 768px) { @content; }
	}
	@else if $point == l {
		@media (min-width: 1024px) { @content; }
	}
	@else if $point == xl {
		@media (min-width: 1440px) { @content; }
	}
	@else if $point { // allows custom breakpoints
		@media (min-width: $point) { @content; }
	}
}