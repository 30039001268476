@import "../breakpoints";

.single-artwork {
	padding: 2rem 0;
	color: #fff;

	> .container {
		transition: opacity 400ms ease-in-out;
	}

	section {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 5rem;
		max-width: 820px;
	}

	.artwork-meta {
		text-align: center;

		.artwork-label {
			display: block;
			font-weight: 300;
			font-size: .675rem;
			letter-spacing: .5px;
			text-transform: uppercase;
			line-height: 1;
		}

		h2,
		h3,
		p {
			margin-top: 0;
			margin-bottom: 2.5rem;
			font-family: 'Arvo', serif;
		}

		h2 {
			font-size: 2rem;
			line-height: 1.25;
		}

		h3 {
			font-size: 1.75rem;
			font-weight: 400;
			line-height: 1.25;
		}

		.artwork-medium {
			font-size: 1.125rem;
		}

		@include breakpoint (m) {
			h2 {
				font-size: 2.5rem;
			}
		}
	}

	.artwork-media {

		.media-image-description-centered {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.media-image,
			.media-description {
				flex-basis: 50%;
			}

			.media-description {
				padding-left: 1rem;
				font-size: 1.25rem;
			}
		}

		.media-image-only {
			.media-image {
				margin-left: auto;
				margin-right: auto;
				max-width: 480px;
				width: auto;
			}
		}

		.media-grid {
			display: flex;
			flex-wrap: wrap;

			.media-col {
				display: flex;
				flex-direction: column;
				align-items: center;
				flex-basis: 100%;
			}

			.media-content {
				padding: 1rem 0;
			}

			.media-description {
				margin-bottom: 1rem;
				font-size: 1rem;
				line-height: 1;
			}

			@include breakpoint (m) {

				.media-col {
					align-items: flex-end;
					flex-basis: 50%;

					&:nth-of-type(2) {
						margin-top: 25%;
					}
				}

				.media-content {
					padding: 2rem 1rem;
				}
			}
		}

		.media-image {
			position: relative;

			> img {
				display: block;
				margin: 0 auto;
				width: 100%;
				max-width: 480px;
				height: auto;
			}

			.enlarge-media {
				position: absolute;
				right: 1rem;
				bottom: 1rem;
				z-index: 2;
				cursor: pointer;

				svg {
					width: 30px;
					height: auto;
				}
			}

			@include breakpoint (m) {
				.enlarge-media {
					svg {
						width: 40px;
						height: auto;
					}
				}
			}
		}
	}

	.artwork-about,
	.artwork-price,
	.artwork-more-information {

		h3 {
			margin-top: 0;
			margin-bottom: 1.5rem;
			text-align: center;
			font-family: 'Arvo', sans-serif;
			line-height: 1.25;
			font-size: 1.75rem;
			font-weight: 600;
		}
		
		.artwork-content {
			font-size: 1.25rem;
		}

		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.artwork-price,
	.artwork-more-information {
		.artwork-content {
			text-align: center;
		}
	}

	.artwork-location {
		svg {
			margin-top: -5px;
		}

		@include breakpoint (m) {
			svg {
				margin-left: 1rem;
			}
		}
	}

	.artist-contact {
		text-align: center;

		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				margin-left: .75rem;
				margin-right: .75rem;
				list-style: none;
			}
		}
	}

	.artwork-next-artist {
		text-align: center;

		.artwork-label {
			display: block;
			font-size: 1.25rem;
			font-weight: 400;
			letter-spacing: .5px;
			text-transform: uppercase;
			line-height: 1;
		}

		a {
			text-decoration: none;
		}

		h2 {
			margin-top: 1rem;
			font-size: 2rem;
			font-family: 'Arvo', serif;
			line-height: 1.25;
			color: #fff;
			cursor: pointer;
		}

		@include breakpoint (m) {
			font-size: 2.5rem;
		}
	}
}

// Lightbox
.lightbox {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9;
	transform: translateY(150px);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	visibility: hidden;
	opacity: 0;
	background-color: transparentize(#53133e, .25);
	transition: all 500ms ease-in-out;

	&.lightbox-open {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}

	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		width: auto;
	}

	.lightbox-close {
		position: absolute;
		top: 1rem;
		left: 2rem;
		background: transparent;
		border: none;
		outline: none;
		color: #fff;
		font-weight: 300;
		font-size: 3rem;
		font-family: 'Josefin Sans', sans-serif;
		cursor: pointer;
	}
}


// Video
.video-wrapper {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 540px;
	width: auto;

	&::before {
		content: '';
		display: block;
		padding-bottom: 56.25%;
	}

	iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}