@import "../breakpoints";

.search {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: all 500ms ease-in-out;

	> .container {
		max-height: 100%;
	}

	.search-form {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
		border-bottom: 1px solid #fff;
		width: 100%;
		max-width: 540px;

		input {
			padding: .5rem;
			width: 100%;
			border: none;
			background-color: transparent;
			color: #fff;
			font-family: 'Arvo', sans-serif;
			font-size: 1.25rem;
			text-align: center;

			&:focus {
				outline: none;
				border: none;
			}

			&::placeholder {
				color: #fff;
				font-family: 'Arvo', sans-serif;
				font-size: 1.25rem;
			}
		}

		.search-button {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background-color: transparent;
			border: none;
			outline: none;
		}
	}

	@include breakpoint (m) {
		padding-top: 3rem;
	}
}