.about-page {
	padding: 2rem 0;
	color: #fff;

	&:focus {
		outline: none;
	}

	.logo {
		margin-bottom: 2rem;
	}

	p {
		font-size: 1.25rem;
	}

	figure {
		margin: 0;
		margin-bottom: 1rem;
	}

	img {
		margin-bottom: .5rem;
		max-width: 100%;
	}

	.aligncenter,
	.has-text-align-center {
		text-align: center;
	}

	.alignright,
	.has-text-align-right {
		text-align: right;
	}

	.blocks-gallery-grid {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
		}
	}

	a {
		color: #fff;
		text-decoration: underline;
	}
}
