@import "../breakpoints";

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	padding-top: 1rem;
	padding-bottom: 1rem;
	
	nav {
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			margin: 0;

			li.search-trigger {
				display: flex;
				align-items: center;
				cursor: pointer;

				svg {
					margin-left: .25rem;
				}
			}

			li {
				margin: 0 1rem;
			}

			li, a {
				color: #fff;
				text-decoration: none;
				font-size: 1rem;
				font-weight: 400;
			}
		}
	}

	@include breakpoint (m) {
		nav {
			.menu {
				justify-content: space-between;

				li {
					margin: 0;
				}
			}
		}
	}
}