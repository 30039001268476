@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');
@import "Animations";
@import "breakpoints";

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	overflow: hidden;
	scroll-behavior: smooth;
	height: 100%;
}

body {
	position: relative;
	height: 100%;
	overflow: auto;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 300;
}

.container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	width: 100%;
	max-width: 1200px;

	&.small {
		max-width: 720px;
	}

	@include breakpoint (m) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

#root {
	height: 100%;
}

.app-main {
	height: 100%;
	background-color: #540f3e;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	transition: all 300ms ease-out;
	overflow: auto;

	@include breakpoint (m) {
		padding: 5.5rem 0 3.5rem 0;
	}

	> div {
		height: 100%;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&:focus {
			outline: none;
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		> div {
			height: 100%;
		}
	}

	&.main-rainbow {
		scrollbar-width: none;
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
	&.main-artwork,
	&.main-about,
	&.main-artists,
	&.main-artworks {
		padding: 7rem 0 3.5rem 0;

		> div {
			overflow: auto;
		}
	}
}
